




































































import { Component, Vue } from 'vue-property-decorator';

import { nameRules } from './validators';
import { dispatchGetTgTasks, dispatchUpdateTgTask } from '@/store/tg-tasks/actions';
import { ITgTaskUpdate } from '@/interfaces/tg-tasks';
import { readOneTgTask } from '@/store/tg-tasks/getters';

@Component
export default class EditTask extends Vue {
  public valid = false;
  public name = '';
  public description = '';
  public deep = 10;
  public nameRules = nameRules;

  public async mounted() {
    await dispatchGetTgTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
      this.deep = this.task.deep;
    } else this.name = this.description = '';
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedTask: ITgTaskUpdate = {
        name: this.name,
        deep: this.deep,
        description: this.description || undefined,
      };
      await dispatchUpdateTgTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/tg-tasks');
    }
  }

  get task() {
    return readOneTgTask(this.$store)(+this.$router.currentRoute.params.id);
  }

  public isNew() {
    return this.task?.status === 'NEW';
  }

}
